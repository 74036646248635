import React, { useState } from 'react'
import logo from "../../Images/logo.png"
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from "../../Redux/authorization";
import { logoutRoute } from "../../Services/authorization";
import * as ROUTES from "../../Constants/routes";
import { toast } from "react-toastify";
import {NAV} from "../../Constants/navbar"
import { Link } from 'react-router-dom';
import PasswordPopup from '../PasswordPopup';

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [showModal, setShowModal] = useState(false);

  const logoutFunc = () => {
    logoutRoute()
      .then(() => {
        if ((user.email)) {
          dispatch(logout());
          navigate(ROUTES.LANDINGPAGE, { replace: true });
        }
      })
      .catch((err) => {
        toast.warn("Can't log out, please try again or refresh the page");
      });
  };

  return (
    <>
    <PasswordPopup
      showModal={showModal}
      setShowModal={setShowModal} 
    />
    <div className='fixed w-screen top-0 left-0 right-0 z-50 h-16 px-3 bg-white drop-shadow-blue flex justify-between items-center'>
        <img src={logo} alt='Royal World' className='h-10'/>

          {/* User Options */}
          {user.isLoggedIn && (
            <div className="relative group cursor-pointer">
              {/* User Profile Image or Initial */}
              {user.photo ? (
                <img
                  src={user.photo}
                  alt="User"
                  className="w-10 h-10 rounded-full object-cover"
                />
              ) : (
                <div
                  className="w-10 h-10 rounded-full bg-darkblue text-white font-semibold flex items-center justify-center"
                  dangerouslySetInnerHTML={{
                    __html: user.name.split("")[0],
                  }}
                />
              )}
              {/* Dropdown Menu */}
              <ul className="group-hover:block absolute top-8 right-3 mt-2 w-60 bg-white rounded-lg border border-placeholder py-2 hidden">
                {/* User Profile Link */}
                <li
                  className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-700"
                  onClick={() => {
                    if (user.role === "ManpowerBA")
                      navigate(
                        `${ROUTES.BA_OFFROLL_DASHBOARD.split(":")[0]}${user.slug}`,
                        {
                          replace: false,
                        }
                      );
                    else if (user.role === "Owner")
                      navigate(
                        `${ROUTES.OWNER_DASHBOARD.split(":")[0]}${user.slug}`,
                        {
                          replace: false,
                        }
                      );
                  }}
                >
                  {user.photo ? (
                    <img
                      src={user.photo}
                      alt="User"
                      className="w-8 h-8 rounded-full mr-2"
                    />
                  ) : (
                    <div
                      className="w-8 h-8 rounded-full bg-gray-400 flex items-center justify-center mr-2"
                      dangerouslySetInnerHTML={{
                        __html: user.name.split("")[0],
                      }}
                    />
                  )}
                  {/* Submenu Content */}
                  <div>
                    <div className="text-base font-semibold">{user.name}</div>
                    <div className="text-xs text-gray-500">{user.email}</div>
                  </div>
                </li>

                {/* Navigation Links */}
                {NAV.map((item, index) =>
                  item.role.includes(user.role) ? (
                    <Link to={item.to} key={index}>
                      <li className="flex items-center text-darkblue px-4 py-2 cursor-pointer hover:bg-greyBorder">
                        <box-icon
                          name={item.icon}
                          type="solid"
                          color="#202774"
                        ></box-icon>
                        <div className="ml-2">{item.name}</div>
                      </li>
                    </Link>
                  ) : null
                )}
                <li
                  className="flex items-center text-darkblue px-4 py-2 cursor-pointer hover:bg-greyBorder"
                  onClick={() => {setShowModal(true)}}
                >
                  <box-icon name="lock" type="solid" color="#202774"></box-icon>
                  <div className="ml-2">Change Password</div>
                </li>
                <li
                  className="flex items-center text-redDark px-4 py-2 cursor-pointer hover:bg-greyBorder"
                  onClick={logoutFunc}
                >
                  <box-icon name="log-out" type="regular" color="#c40606"></box-icon>
                  <div className="ml-2">Logout</div>
                </li>
              </ul>
            </div>
          )}
    </div>
    </>
  )
}
export default Navbar