import axios from "axios";
import axiosJWT from "./axiosJWT";

export const registration = async (data) => {
  return axios({
    method: "POST",
    url: "/api/v1/auth/register",
    data: {
      name: data.name,
      email: data.email,
      dob: data.dob,
      empNo: data.empNo,
      empType: data.empType,
      gender: data.gender,
      branch: data.branch,
      contactNumber: data.contactNumber,
      companyName: data.companyName,
      companyAddress: data.companyAddress,
      state: data.state,
      pincode:data.pincode,
      companyCode:data.companyCode
    },
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const signIn = async (data) => {
    return axios({
      method: "POST",
      url: "/api/v1/auth/login",
      data: {
        email: data.email,
        password: data.password,
      },
      headers: {
        "Content-Type": "application/json",
      },
    });
};

export const updatePassword = (data) => {
  return axiosJWT({
    method: "PUT",
    url: `/api/v1/auth/updatePassword`,
    data: {
      oldPassword: data.oldPassword,
      newPassword: data.newPassword,
    },
    headers: {
      'Content-Type' : 'application/json'
    },
  });
};

export const forgotPassword = (data) => {
  return axios({
    method: "post",
    url: "/api/v1/auth/forgotPassword",
    data: {
      email: data.email,
    },
    headers: {
      'Content-Type' : 'application/json'
    },
  });
};

export const refreshRoute = () => {
  return axios({
    method: "post",
    url: "/api/v1/auth/refresh",
  });
};

export const logoutRoute = () => {
  return axios({
    method: "GET",
    url: "/api/v1/auth/logout",
  });
};
