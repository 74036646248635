import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: "",
  photo: "",
  isLoggedIn: false,
  isFirstLogin: false,
  accessToken: "",
  email: "",
  loader: true,
  role: "All",
  slug: "",
};

const saveStateToLocalStorage = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("userState", serializedState);
  } catch (e) {
    // console.warn("Could not save state", e);
  }
};

// Helper function to load state from localStorage
const loadStateFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem("userState");
    if (serializedState === null) return initialState;
    return JSON.parse(serializedState);
  } catch (e) {
    console.warn("Could not load state", e);
    return initialState;
  }
};

const persistedState = loadStateFromLocalStorage();

export const authorizationSlice = createSlice({
  name: "authorization",
  initialState: persistedState,
  reducers: {
    login: (state, action) => {
      state.isLoggedIn = true;
      state.name = action.payload.data.name;
      state.email = action.payload.data.email;
      state.photo = action.payload.data.profile;
      state.accessToken = action.payload.accessToken;
      state.role = action.payload.data.role;
      state.isFirstLogin = action.payload.data.isFirstLogin;
      saveStateToLocalStorage(state); // Save state to localStorage
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.isFirstLogin = null;
      state.name = null;
      state.photo = null;
      state.accessToken = null;
      state.email = null;
      state.role = "All";
      state.slug = null;
      saveStateToLocalStorage(state); // Save state to localStorage
    },
    loaderTrue: (state) => {
      state.loader = true;
    },
    loaderFalse: (state) => {
      state.loader = false;
    },
  },
});
export const { login, logout, loaderTrue, loaderFalse } =
  authorizationSlice.actions;

export default authorizationSlice.reducer;
