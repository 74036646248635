import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ authRole, children }) => {
  const { isLoggedIn, role } = useSelector((state) => state.user);
  return isLoggedIn && authRole.includes(role) ? (
    children
  ) : role === "ManpowerBA" ? (
    <Navigate to="/dashboard/BA/offroll" />
  ) : role === "CompanyAdmin" ? (
    <Navigate to="/dashboard/companyAdmin/offRoll" />
  ) : role === "Owner" ? (
    <Navigate to="/dashboard/owner" />
  ) : role === "All" ? (
    <Navigate to="/" />
  ) : null;
};

const NotAccessibleRoute = ({ children }) => {
  const { isLoggedIn, role } = useSelector((state) => state.user);
  return isLoggedIn ? (
    role === "ManpowerBA" ? (
      <Navigate to="/dashboard/BA/offroll" />
    ) : role === "CompanyAdmin" ? (
      <Navigate to="/dashboard/companyAdmin/offRoll" />
    ) : role === "Owner" ? (
      <Navigate to="/dashboard/owner" />
    ) : null
  ) : (
    children
  );
};

export { ProtectedRoute, NotAccessibleRoute };
