import {jwtDecode} from "jwt-decode";
import { store } from "../Redux/store";
import { refreshRoute } from "./authorization";
import { login } from "../Redux/authorization";
import axios from "axios";

const axiosJWT = axios.create();

axiosJWT.interceptors.request.use(
  async (config) => {
    const { user } = store.getState();
    // {console.log(user)}
    let currentDate = new Date().getTime();
    const decodedToken = jwtDecode(user.accessToken);

    // console.log(decodedToken,"decoded")
    if (decodedToken.exp * 1000 < currentDate) {

      const data = await refreshRoute();
      store.dispatch(login(data.data));
      config.headers["authorization"] = `Bearer ${data.data.accessToken}`;
    } else {
      config.headers["authorization"] = `Bearer ${user.accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosJWT;
