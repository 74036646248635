import React from 'react'
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import * as ROUTES from "../Constants/routes";
import { updatePassword } from '../Services/authorization';
import { useSelector } from 'react-redux';

const PasswordPopup = ({ showModal,setShowModal}) => {
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.user);

  const [user, setUser] = useState({
    oldPassword:"", 
    newPassword:"", 
  });

  const update = () => {
    let role;
    
    const {  
      oldPassword, 
      newPassword, 
    } = user;

    if (!oldPassword) {
      toast.warn("Please enter old password");
      return false;
    } else if (!newPassword) {
      toast.warn("Please enter new password");
      return false;
    }

    updatePassword(user)
      .then((result) => {
        role = userInfo.role;
        // console.log(role)
        if (role === "ManpowerBA") {
          navigate(ROUTES.BA_OFFROLL_DASHBOARD)
        } else if (role === "CompanyAdmin") {
          navigate(ROUTES.COMPANYADMIN_OFFROLL_DASHBOARD)
        } else if (role === "Owner") {
          navigate(ROUTES.OWNER_DASHBOARD)
        }
        setShowModal(false);
        toast.success(result.data.message, { autoClose: 2000 });
      })
      .catch((err) => {
        const errorMessage = err.response?.data?.message || "Error, please try again";
        toast.warn(errorMessage);
      });
  };

  return (
    <>
    { showModal ? (
    <div className='z-20 fixed w-full h-full bg-[#49498191]'>
      <div className='z-50 flex flex-col justify-center px-14 sm:px-8 pb-10 bg-white shadow-lg rounded-xl w-2/8 sm:w-[90vw] fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 sm:h-[65vh]'>
        <header className='my-10 sm:my-8 font-semibold text-2xl sm:text-xl text-center text-darkblue'>
          Change your Password
          {userInfo.isFirstLogin ? <p className='text-sm font-normal pt-2'>It's your first login, please change your password.</p> : ""}
          </header>

        <form className=''>
          <section className='w-full flex flex-col justify-center sm:flex-col'>
            <div className='w-full mr-2 mb-3 flex flex-col'>
              <label>Old Password</label>
              <input
                type='password'
                placeholder="Enter Old Password"
                onChange={(e) => {
                  setUser({ ...user, oldPassword: e.target.value });
                }}
              />
            </div> 
            <div className='w-full mr-2 mb-3 flex flex-col'>
              <label>New Password</label>
              <input
                type='password'
                placeholder="Enter New Password"
                onChange={(e) => {
                  setUser({ ...user, newPassword: e.target.value });
                }}
              />
            </div> 
            <div className='w-full mr-2 mb-3 flex flex-col'>
              <label>Confirm Password</label>
              <input
                type='password'
                placeholder="Confirm New Password"
              />
            </div> 
          </section>
        </form>

        <div className='flex justify-center mt-6 sm:mt-4 gap-x-4'>
          <button
          className='h-12 sm:h-9 px-4 font-medium text-lg rounded-md text-white bg-gradient-to-b from-lightblue to-darkblue'
            onClick={() => {
              update();
              // setShowModal(false);
            }}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  ) : null}
  </>
  )
}

export default PasswordPopup
