import React from 'react'
import loaderImg from "../../Images/loaderblue.json"
import Lottie from "react-lottie";

const Loader = ({LoaderMsg}) => {
    const loaderOptions = {
        loop: true,
        autoplay: true,
        animationData: loaderImg,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };

  return (
    <div className='z-20 fixed w-full h-full bg-[#0c192a84] flex  justify-center items-center'>
        <div className='h-2/5 w-2/5'>
          <Lottie options={loaderOptions} />
        </div>
    </div>
  )
}

export default Loader