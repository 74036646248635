import React from 'react'

const Footer = () => {
  return (
    <footer className=' h-14 bg-gradient-to-b from-lightblue to-darkblue flex sm:flex-col sm:h-24 sm:justify-around sm:px-10 py-4 justify-between items-center px-20 mt-16'>
        <header className='text-md font-semibold text-center text-white'>Developed by Royal World</header>
        <header className='text-md font-light text-center text-white'>Copyright ©Royalworld</header>
    </footer>
  )
}

export default Footer