import React, { useState } from 'react'
import { useNavigate } from 'react-router';
import { forgotPassword } from '../Services/authorization';
import { toast } from 'react-toastify';
import * as ROUTES from "../Constants/routes";

const ForgotPasswordPage = () => {
  const navigate = useNavigate();

  const [user, setUser] = useState({
    email:"", 
  });

  const forgot = () => {
    const {       
      email, 
    } = user;

    if (!email.trim()) {
      toast.warn("Please enter email id");
      return false;
    }

    forgotPassword(user)
      .then((result) => {
        navigate(ROUTES.LANDINGPAGE)
      })
      .catch((err) => {
        const errorMessage = err.response?.data?.message || "Login failed, please try again";
        toast.warn(errorMessage);
      });
  };
  
  return (
    <main>
      <div className='w-full h-[80.7vh] flex flex-col justify-center items-center'>
      <div className='w-1/2 flex flex-col justify-center px-14 sm:px-8 pb-10 bg-white border-[0.3px] border-lightGreyBg rounded-xl'>
        <header className='my-10 sm:my-8 font-semibold text-2xl sm:text-xl text-center text-darkblue'>Forgot Password</header>

        <form className=''>
          <section className='w-full flex flex-col justify-center sm:flex-col'>
            <div className='w-full mr-2 mb-3 flex flex-col'>
              <label>Registered Email</label>
              <input
                placeholder="Enter Registered Email"
                onChange={(e) => {
                  setUser({ ...user, email: e.target.value.toLowerCase().trim() });
                }}
              />
            </div> 
          </section>
        </form>

        <div className='flex justify-center mt-6 sm:mt-4 gap-x-4'>
          <button
          className='h-10 sm:h-9 px-4 font-medium text-lg rounded-lg text-white bg-gradient-to-b from-lightblue to-darkblue'
          onClick={() => {
            forgot();
            // setShowModal2(true);
            // setShowModal(false);
          }}
          >
            Send Email
          </button>
        </div>
        <p className='text-center text-sm pt-4 flex items-center justify-center'>
           Note: A new password will be sent on the entered email.
        </p>
      </div>
      </div>
    </main>
  )
}

export default ForgotPasswordPage