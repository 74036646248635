import * as ROUTES from "./routes";

export const NAV = [
  {
    to: ROUTES.BA_OFFROLL_DASHBOARD,
    icon: "calendar-plus",
    name: "OffRoll Dashboard",
    role: ["ManpowerBA"],
  },
  {
    to: ROUTES.OWNER_DASHBOARD,
    icon: "listcheck", //onroll
    name: "Owner Dashboard",
    role: ["Owner"],
  },
  {
    to: ROUTES.COMPANYADMIN_OFFROLL_DASHBOARD,
    icon: "pen",
    name: "Admin OffRoll Dashboard",
    role: ["CompanyAdmin"],
  },
];
