import { Suspense, lazy, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProtectedRoute, NotAccessibleRoute } from "./Helpers/ProtectedRoute";
import { useLocation, Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { refreshRoute } from "./Services/authorization";
import { ToastContainer } from "react-toastify";
import { login, loaderTrue, loaderFalse } from "./Redux/authorization";
import "boxicons"
import * as ROUTES from "./Constants/routes";
import Loader from './Components/CommonComponents/Loader.js';
import Navbar from './Components/CommonComponents/Navbar';
import Footer from './Components/CommonComponents/Footer';
import ForgotPassword from './Pages/ForgotPasswordPage.js';

const LandingPage = lazy(() => import('./Pages/LandingPage'))
const BAoffRollDashboard = lazy(() => import ('./Pages/BAoffRollDashboard'));
const EachRequisition = lazy(() => import ('./Pages/EachRequisition'));
const CreateRequisition = lazy(() => import ('./Pages/CreateRequisition'));
const Products = lazy(() => import ('./Pages/Products.js'));
const AdminoffRollDashboard = lazy(() => import ('./Pages/AdminoffRollDashboard.js'));
const PaymentPage = lazy(() => import ('./Pages/PaymentPage.js'));
const SingleProduct = lazy(() => import ('./Pages/SingleProduct.js'));
const OwnerDashboard = lazy(() => import ('./Pages/OwnerDashboard.js'));
const EachCompany = lazy(() => import ('./Pages/EachCompany.js'));

const App = () => {
  const { loader, isLoggedIn } = useSelector((state) => state.user);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loaderTrue());
    refreshRoute()
      .then((user) => {
        if (user.data.success === true) {
          dispatch(login(user.data));
        }
        dispatch(loaderFalse());
      })
      .catch((err) => {
        dispatch(loaderFalse());
      });
  }, []);

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
      <>
      {location.pathname !== ROUTES.LANDINGPAGE ? (
        <Navbar />
      ):null}
      
      <Suspense fallback={<Loader />}>
      <Routes>
        <Route
          path={ROUTES.LANDINGPAGE}
          element={
            <NotAccessibleRoute>
              <LandingPage/>
            </NotAccessibleRoute>
          }
        />
        <Route
          path={ROUTES.FORGOT_PASSWORD}
          element={
            <ForgotPassword />
          }
        />
        <Route
          path={ROUTES.PRODUCTS}
          element={
            // <ProtectedRoute authRole={["ManpowerBA", "Team"]}>
              <Products />
            // </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.BA_OFFROLL_DASHBOARD}
          element={
            <ProtectedRoute authRole={["ManpowerBA"]}>
              <BAoffRollDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.COMPANYADMIN_OFFROLL_DASHBOARD}
          element={
            <ProtectedRoute authRole={["CompanyAdmin", "Developer"]}>
              <AdminoffRollDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.EACH_REQUISITION}
          element={
            <ProtectedRoute authRole={["ManpowerBA", "CompanyAdmin", "Developer" , "Owner"]}>
              <EachRequisition />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.EACH_COMPANY}
          element={
            <ProtectedRoute authRole={["Developer" , "Owner"]}>
              <EachCompany />
            </ProtectedRoute>
          }
        />
        {/* <Route
          path={ROUTES.SITEMANAGER_EACH_REQUISITION}
          element={
            <ProtectedRoute authRole={["ManpowerBA", "Team"]}>
              <SiteManagerEachRequisition />
            </ProtectedRoute>
          }
        /> */}
        <Route
          path={ROUTES.CREATE_REQUISITION}
          element={
            <ProtectedRoute authRole={["ManpowerBA", "Developer"]}>
              <CreateRequisition />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.PAYMENT_DETAILS}
          element={
            <ProtectedRoute authRole={["ManpowerBA", "Developer"]}>
              <PaymentPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.SINGLE_PRODUCT}
          element={
            <ProtectedRoute authRole={["ManpowerBA", "CompanyAdmin", "Owner", "Developer"]}>
              <SingleProduct />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.OWNER_DASHBOARD}
          element={
            <ProtectedRoute authRole={["Owner", "Developer"]}>
              <OwnerDashboard />
            </ProtectedRoute>
          }
        />
      </Routes>
      <Footer />
      </Suspense>
      
      <ToastContainer autoClose={3000} />
      </>
      )}
    </>
  );
}

export default App;
