//Landing Page Route
export const LANDINGPAGE = "/";

//password
export const FORGOT_PASSWORD = "/password/forgot";

//Dashboards
export const BA_OFFROLL_DASHBOARD = "/dashboard/BA/offroll";
export const COMPANYADMIN_OFFROLL_DASHBOARD = "/dashboard/companyAdmin/offRoll";
export const OWNER_DASHBOARD = "/dashboard/owner"

//requisition
export const EACH_REQUISITION = "/requisition/:slug";
export const CREATE_REQUISITION = "/requisition/create";
export const PAYMENT_DETAILS = "/requisition/payment/:slug";
export const SITEMANAGER_EACH_REQUISITION = "/requisition/sitemanager";

//products
export const PRODUCTS = "/products";
export const SINGLE_PRODUCT = "/product/:slug";

//company
export const EACH_COMPANY = "/company/:slug"

//Privacy Route
export const PRIVACY = "/privacy";
